const signUp = [
  {
    name: "Username cannot be empty",
    fields: ["username"],
    message: "Username cannot be empty",
  },
  {
    name: "User already exists",
    fields: ["username"],
    message: "User already exists",
  },
  {
    name: "Password cannot be empty",
    fields: ["password"],
    message: "Password cannot be empty",
  },
  {
    name: "Attribute value for email must not be null",
    fields: ["email"],
    message: "Email address cannot be empty",
  },
  {
    name: "policy",
    fields: ["policy"],
    message: "Please accept the policy and terms of conditions!",
  },
  {
    name: "Username cannot have empty spaces",
    fields: ["username"],
    message: "Username cannot have empty spaces",
  },
  {
    name:
      "Username cannot be of email format, since user pool is configured for email alias.",
    fields: ["username"],
    message: "Username cannot be of email format",
  },
];

const passwordPolicy = [
  {
    name:
      "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6",
    fields: ["password"],
    message: "Password must be at least 8 charactes",
  },
  {
    name: "Password did not conform with policy: Password not long enough",
    fields: ["password"],
    message: "Password must be at least 8 characters",
  },
  {
    name:
      "Password did not conform with policy: Password must have lowercase characters",
    fields: ["password"],
    message: "Password must contain at least one lowercase character",
  },
  {
    name:
      "Password did not conform with policy: Password must have numeric characters",
    fields: ["password"],
    message: "Password must contain at least one numeric character",
  },
  {
    name:
      "Password did not conform with policy: Password must have symbol characters",
    fields: ["password"],
    message: "Password must contain at least one special character",
  },
  {
    name:
      "Password did not conform with policy: Password must have uppercase characters",
    fields: ["password"],
    message: "Password must contain at least one uppercase character",
  },
];

const forgotPassword = [
  {
    name: "Username cannot be empty",
    fields: ["username"],
    message: "Username cannot be empty",
  },
  {
    name: "Attempt limit exceeded, please try after some time.",
    fields: ["username"],
    message: "Too many attempts, please try again in a few minutes",
  },
  {
    name: "Username/client id combination not found.",
    fields: ["username"],
    message: "Username not found",
  },
  {
    name:
      "Cannot reset password for the user as there is no registered/verified email or phone_number",
    fields: ["username"],
    message:
      "Cannot reset password for the user as there is no registered/verified email",
  },
  {
    name: "Password cannot be empty",
    fields: ["password"],
    message: "Password cannot be empty",
  },
  {
    name: "Confirmation code cannot be empty",
    fields: ["code"],
    message: "Confirmation code cannot be empty",
  },
  {
    name: "Invalid verification code provided, please try again.",
    fields: ["code"],
    message: "Invalid verification code provided",
  },
];

export const confirmSignUpErrors = [
  {
    name: "Confirmation code cannot be empty",
    fields: ["code"],
    message: "Confirmation code cannot be empty",
  },
  {
    name: "Invalid verification code provided, please try again.",
    fields: ["code"],
    message: "Invalid verification code provided",
  },
];

export const signInErrors = [
  {
    name: "Username cannot be empty",
    fields: ["username"],
    message: "Username cannot be empty",
  },
  {
    name: "Custom auth lambda trigger is not configured for the user pool.",
    fields: ["password"],
    message: "Password cannot be empty",
  },
  {
    name: "User does not exist.",
    fields: ["username", "password"],
    message: "Incorrect username or password",
  },
  {
    name: "Incorrect username or password.",
    fields: ["username", "password"],
    message: "Incorrect username or password",
  },
  {
    name: "Invalid username format",
    fields: ["username"],
    message: "Invalid username format",
  },
];

export const verifyContactErrors = [
  {
    name: "Option is not selected",
    fields: ["contact"],
    message: "Option is not selected",
  },
  {
    name: "Confirmation code cannot be empty",
    fields: ["code"],
    message: "Confirmation code cannot be empty",
  },
  {
    name: "Invalid verification code provided, please try again.",
    fields: ["code"],
    message: "Invalid verification code provided",
  },
];

export const signUpErrors = [...signUp, ...passwordPolicy];
export const forgotPasswordErrors = [...forgotPassword, ...passwordPolicy];

const allErrors = [...signInErrors, ...signUpErrors, ...forgotPasswordErrors];

export const getError = (awsError) =>
  allErrors.find((error) => error.name === awsError) || {
    fields: [],
    message: "",
  };
