import ReactGA from "react-ga";

const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
const _shield = (enabled, cb) => (enabled ? cb : () => {});

// GA Analytics Service
const Analytics = () => {
  // The service is enabled if it has a tracking id
  const isEnabled = !!trackingId;

  const initialize = (options) => {
    ReactGA.initialize(trackingId, options);
  };

  const addTracker = (gaOptions) => {
    ReactGA.addTrackers([{ trackingId, ...gaOptions }]);
  };

  const exception = (description, fatal = true) => {
    ReactGA.exception({ description, fatal });
  };

  const pageView = (path) => {
    ReactGA.pageview(path);
  };

  return {
    initialize: _shield(isEnabled, initialize),
    addTracker: _shield(isEnabled, addTracker),
    exception: _shield(isEnabled, exception),
    pageView: _shield(isEnabled, pageView),
  };
};

// Exports
export default Analytics();
