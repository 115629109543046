import React, { Suspense, lazy} from "react";
import {
  Authenticator,
  ForgotPassword,
  VerifyContact,
  ConfirmSignUp,
  SignIn,
  SignUp,
} from "aws-amplify-react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core";

import AWSConfig from "../../config/aws";

import AwsTheme from "./AwsTheme";
import LightTheme from "./LightTheme";

import CustomForgotPassword from "../auth/CustomForgotPassword";
import CustomVerifyContact from "../auth/CustomVerifyContact";
import CustomConfirmSignUp from "../auth/CustomConfirmSignUp";
import CustomSignIn from "../auth/CustomSignIn";
import CustomSignUp from "../auth/CustomSignUp";

import ErrorBoundary from "./ErrorBoundary";
import ServiceWorker from "./ServiceWorker";

const AppSignedIn = lazy(() => import("./AppSignedIn"));

const AuthState = (props) => {
  const { authState } = props;

  if (authState === "loading") {
    return null;
  }

  if (authState === "signedIn") {
    return <AppSignedIn {...props} />;
  }

  if (authState === "signIn") {
    const code = new URLSearchParams(window.location.search).get("code");

    if (code) return null;
  }

  return (
    <>
      <CustomSignIn override={SignIn} {...props} />
      <CustomSignUp override={SignUp} {...props} />
      <CustomForgotPassword override={ForgotPassword} {...props} />
      <CustomConfirmSignUp override={ConfirmSignUp} {...props} />
      <CustomVerifyContact override={VerifyContact} {...props} />
    </>
  );
};

const App = () => (
  <ThemeProvider theme={LightTheme}>
    <CssBaseline />
    <ErrorBoundary module="App">
      <ServiceWorker />
      <BrowserRouter>
        <Suspense fallback={null}>
          <Switch>
            <Route>
              <Authenticator
                hideDefault={true}
                amplifyConfig={AWSConfig}
                theme={AwsTheme}
                authState={
                  window.localStorage.getItem("accountCreated")
                    ? "signIn"
                    : "signUp"
                }
              >
                <AuthState />
              </Authenticator>
            </Route>
          </Switch>
        </Suspense>
      </BrowserRouter>
    </ErrorBoundary>
  </ThemeProvider>
);

export default App;
