import React, { useEffect, useState } from "react";
import MuiAlert from "@material-ui/lab/Alert";
import { Button, Icon, Snackbar } from "@material-ui/core";

import * as serviceWorker from "../../services/serviceWorker";

const ServiceWorker = () => {
  const [waitingWorker, setWaitingWorker] = useState();
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);

  const handleSnackbarClose = () => {
    setNewVersionAvailable(false);
  };

  const handleSnackbarReload = () => {
    waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setNewVersionAvailable(false);
    window.location.reload();
  };

  const handleServiceWorkerUpdate = (registration) => {
    setWaitingWorker(registration?.waiting);
    setNewVersionAvailable(true);
  };

  useEffect(() => {
    serviceWorker.register({ onUpdate: handleServiceWorkerUpdate });
  }, []);

  return (
    <Snackbar
      open={newVersionAvailable}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity="info"
        action={
          <React.Fragment>
            <Button color="secondary" onClick={handleSnackbarReload}>
              reload
            </Button>
            <Button
              color="inherit"
              aria-label="close"
              onClick={handleSnackbarClose}
            >
              <Icon fontSize="small">close</Icon>
            </Button>
          </React.Fragment>
        }
      >
        New version available
      </MuiAlert>
    </Snackbar>
  );
};

export default ServiceWorker