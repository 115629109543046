import React from "react";
import { Auth } from "aws-amplify";
import { SignUp } from "aws-amplify-react";

import Analytics from "../../services/analytics";

import { getError } from "../helpers/formValidation/auth";
import AuthContainer from "./common/AuthContainer";
import MainPanel from "./common/MainPanel";
import PasswordField from "./common/PasswordField";
import SignInUpProviders from "./common/SignInUpProviders";

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  FormHelperText,
} from "@material-ui/core";

export class CustomSignUp extends SignUp {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signUp"];
    this.state = {
      policyChecked: false,
      loading: false,
      error: null,
      showModal: false,
    };

    this.setPolicy = this.setPolicy.bind(this);
  }

  async signUp(e) {
    e.preventDefault();
    this.setState({ loading: true });

    const { username, password, email } = this.inputs;

    const params = {
      username: username,
      password: password,
      attributes: {
        email: email,
      },
    };

    if (this.state.policyChecked) {
      try {
        await Auth.signUp(params);
        this.changeState("confirmSignUp", params);
      } catch (error) {
        if (params.username.includes(" ")) {
          this.setState({ error: "Username cannot have empty spaces" });
        } else this.setState({ error: error.message });
      }
    } else {
      this.setState({ error: "policy" });
    }

    this.setState({ loading: false });
  }

  handleInputChange(e) {
    this.setState({ error: null });
    super.handleInputChange(e);
  }

  validationProps(field) {
    if (!this.state.error) {
      return;
    }

    const { fields, message } = getError(this.state.error);

    return {
      error: fields.includes(field),
      helperText: fields[fields.length - 1] === field ? message : "",
    };
  }

  setPolicy() {
    this.setState((prevState) => ({
      error: null,
      policyChecked: !prevState.policyChecked,
    }));
  }

  showComponent() {
    Analytics.pageView("/sign-up");

    return (
      <AuthContainer>
        <MainPanel
          title="Sign up"
          bottomText="Already have an account?"
          action="Sign in"
          bottomActionHandler={() => super.changeState("signIn")}
        >
          <Box position="relative">
            <form onSubmit={this.signUp}>
              <Box mb={1}>
                <Typography variant="overline" component="label" htmlFor="username">
                  Username
                </Typography>
                <TextField
                  required
                  id="username"
                  key="username"
                  name="username"
                  type="text"
                  variant="outlined"
                  placeholder="Username"
                  autoComplete="username"
                  fullWidth
                  onChange={this.handleInputChange}
                  {...this.validationProps("username")}
                />
              </Box>

              <Box mb={1}>
                <Typography variant="overline" component="label" htmlFor="email">
                  Email Address
                </Typography>
                <TextField
                  required
                  id="email"
                  key="email"
                  name="email"
                  type="email"
                  variant="outlined"
                  fullWidth
                  placeholder="Email"
                  onChange={this.handleInputChange}
                  {...this.validationProps("email")}
                />
              </Box>

              <PasswordField
                required
                handleInputChange={this.handleInputChange}
                id="password"
                label="Password"
                {...this.validationProps("password")}
              />
              <Box my={2}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Checkbox checked={this.state.policyChecked} onClick={this.setPolicy} />
                  </Grid>
                  <Grid item xs>
                    <Box color="primary.dark">
                      <Typography color="inherit" variant="body2" onClick={this.setPolicy}>
                        By creating an account you agree to the{" "}
                        <a
                          href="https://www.slashscore.ai/terms-of-service"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "underline" }}
                        >
                          terms of service
                        </a>{" "}
                        and our{" "}
                        <a
                          href="https://www.slashscore.ai/privacy-policy"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "underline" }}
                        >
                          privacy policy
                        </a>
                        .
                      </Typography>
                    </Box>
                  </Grid>
                  {this.validationProps("policy") && (
                    <Grid item xs={12}>
                      <FormHelperText>
                        <Box color="red" component="span">
                          {this.validationProps("policy").helperText}
                        </Box>
                      </FormHelperText>
                    </Grid>
                  )}
                </Grid>
              </Box>

              <Button type="submit" variant="contained" color="primary" fullWidth disabled={this.state.loading}>
                SIGN UP
                {this.state.loading && (
                  <Box ml={2}>
                    <CircularProgress size="1rem" />
                  </Box>
                )}
              </Button>
            </form>

            <SignInUpProviders type="Sign up" />
          </Box>
        </MainPanel>
      </AuthContainer>
    );
  }
}

export default CustomSignUp;
