import React from "react";
import { Auth } from "aws-amplify";
import { VerifyContact } from "aws-amplify-react";

import Analytics from "../../services/analytics";

import { getError } from "../helpers/formValidation/auth";
import AuthContainer from "./common/AuthContainer";
import MainPanel from "./common/MainPanel";

import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  FormHelperText,
} from "@material-ui/core";

const useStyle = (theme) => ({
  skip: {
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
  radio: {
    color: theme.palette.primary.dark,
  },

  checked: {
    color: `${theme.palette.primary.dark} !important`,
  },
});

export class CustomVerifyContact extends VerifyContact {
  constructor(props) {
    super(props);
    this._validAuthStates = ["verifyContact"];
    this.state = { err: "" };
  }

  verify() {
    const { contact, checkedValue } = this.inputs;
    if (!contact) this.setState({ err: "Option is not selected" });

    Auth.verifyCurrentUserAttribute(checkedValue)
      .then(() => this.setState({ verifyAttr: checkedValue }))
      .catch((err) => this.setState({ err: err.message }));
  }

  submit() {
    const attr = this.state.verifyAttr;
    const { code } = this.inputs;

    Auth.verifyCurrentUserAttributeSubmit(attr, code)
      .then(() => {
        this.changeState("signedIn", this.props.authData);
        window.localStorage.setItem("emailVerified", "true");
        this.setState({ verifyAttr: null });
      })
      .catch((err) => this.setState({ err: err.message }));
  }

  handleInputChange(e) {
    this.setState({ error: null });
    super.handleInputChange(e);
  }

  validationProps(field) {
    if (!this.state.error) {
      return;
    }

    const { fields, message } = getError(this.state.error);

    return {
      error: fields.includes(field),
      helperText: fields[fields.length - 1] === field ? message : "",
    };
  }

  submitView() {
    return (
      <Box mb={2}>
        <form>
          <Typography variant="overline" component="label" htmlFor="code">
            Code
          </Typography>

          <TextField
            id="code"
            name="code"
            key="code"
            type="text"
            variant="outlined"
            placeholder="Code"
            fullWidth
            onChange={this.handleInputChange}
            {...this.validationProps("code")}
          />
        </form>
      </Box>
    );
  }

  verifyView() {
    const { classes } = this.props;
    var user = this.props.authData;
    var unverified = user.unverified;
    var email = unverified.email,
      phone_number = unverified.phone_number;

    return (
      <Box mb={2}>
        <Typography variant="h6">Please select one of the options :</Typography>

        <FormControl
          component="fieldset"
          error={this.validationProps("contact")}
        >
          <RadioGroup name="contact" onChange={this.handleInputChange}>
            {email && (
              <FormControlLabel
                value="email"
                key="email"
                name="contact"
                control={
                  <Radio
                    classes={{ root: classes.radio, checked: classes.checked }}
                  />
                }
                label="Email"
              />
            )}
            {phone_number && (
              <FormControlLabel
                value="phone_number"
                key="phone_number"
                name="contact"
                control={<Radio />}
                label="Phone number"
              />
            )}
          </RadioGroup>
          {this.validationProps("contact") && (
            <FormHelperText>
              {this.validationProps("contact").helperText}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
    );
  }

  showComponent() {
    const { classes, authData } = this.props;

    Analytics.pageView("/verify-contact");

    return (
      <AuthContainer>
        <MainPanel
          title="Verify your contact"
          action="Back to sign in"
          bottomActionHandler={() => super.changeState("signIn")}
        >
          <Box>
            {this.state.verifyAttr ? this.submitView() : this.verifyView()}

            <Box mb={1} mt={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={this.state.verifyAttr ? this.submit : this.verify}
              >
                {this.state.verifyAttr ? "Submit" : "Verify"}
              </Button>
            </Box>

            <Box textAlign="right" mt={2}>
              <Typography variant="body2" color="secondary">
                <Link
                  className={classes.skip}
                  onClick={() => this.changeState("signedIn", authData)}
                >
                  Skip for now
                </Link>
              </Typography>
            </Box>
          </Box>
        </MainPanel>
      </AuthContainer>
    );
  }
}

export default withStyles(useStyle)(CustomVerifyContact);
