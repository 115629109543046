import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Icon, TextField, Typography } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.dark,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const PasswordField = (props) => {
  const classes = useStyle();
  const { id, handleInputChange, label, disabled, error, helperText } = props;
  const [visible, setVisible] = useState(false);

  const passwordVisibilityHandler = () => {
    var obj = document.getElementById(id);

    if (obj.type === "password") {
      obj.type = "text";
      setVisible(true);
    } else {
      obj.type = "password";
      setVisible(false);
    }
  };

  return (
    <Box mb={1}>
      <Typography variant="overline" component="label" htmlFor={id}>
        {label}
      </Typography>
      <TextField
        fullWidth
        error={error}
        helperText={helperText}
        id={id}
        name={id}
        key={id}
        type="password"
        autoComplete="current-password"
        variant="outlined"
        placeholder="********"
        disabled={disabled}
        classes={{ root: classes.textField }}
        onChange={handleInputChange}
        InputProps={{
          endAdornment: (
            <Icon onClick={passwordVisibilityHandler} className={classes.icon}>
              {visible ? "visibility_off" : "visibility"}
            </Icon>
          ),
        }}
      />
    </Box>
  );
};

export default PasswordField;
