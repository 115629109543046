import React from "react";
import { Auth } from "aws-amplify";
import { ForgotPassword } from "aws-amplify-react";

import Analytics from "../../services/analytics";

import { getError } from "../helpers/formValidation/auth";
import AuthContainer from "./common/AuthContainer";
import MainPanel from "./common/MainPanel";
import PasswordField from "./common/PasswordField";

import { Box, Button, Link, TextField, Typography } from "@material-ui/core";

export class CustomForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    this._validAuthStates = ["forgotPassword"];
    this.state = { delivery: false, username: null, err: "" };
  }

  send() {
    const username = this.state.username || this.inputs.username;
    Auth.forgotPassword(username)
      .then(() => {
        this.setState({ delivery: true, username: username });
      })
      .catch((err) => {
        this.setState({ err: err.message });
      });
  }

  submit() {
    const username = this.state.username;
    const { code, password } = this.inputs;

    Auth.forgotPasswordSubmit(username, code, password)
      .then(() => {
        this.setState({ delivery: null });
        this.changeState("signIn");
      })
      .catch((err) => {
        this.setState({ err: err.message });
      });
  }

  handleInputChange(e) {
    this.setState({ err: null });
    super.handleInputChange(e);
  }

  validationProps(field) {
    if (!this.state.err) {
      return;
    }

    const { fields, message } = getError(this.state.err);

    return {
      error: fields.includes(field),
      helperText: fields[fields.length - 1] === field ? message : "",
    };
  }

  sendView() {
    return (
      <form>
        <Box mb={2}>
          <Typography variant="overline" component="label" htmlFor="username">
            Username
          </Typography>
          <TextField
            required
            id="username"
            name="username"
            key="username"
            type="text"
            variant="outlined"
            placeholder="Enter your username"
            fullWidth
            onChange={this.handleInputChange}
            {...this.validationProps("username")}
          />
        </Box>
        <Box mb={1}>
          <Button
            text="Send Code"
            variant="contained"
            color="primary"
            fullWidth
            onClick={this.send}
          >
            Send Code
          </Button>
        </Box>
      </form>
    );
  }

  submitView() {
    return (
      <form>
        <Typography variant="overline" component="label" htmlFor="code">
          Code
        </Typography>

        <TextField
          fullWidth
          required
          id="code"
          name="code"
          key="code"
          type="text"
          variant="outlined"
          placeholder="Code"
          onChange={this.handleInputChange}
          {...this.validationProps("code")}
        />
        <PasswordField
          required
          handleInputChange={this.handleInputChange}
          id="password"
          label="Password"
          {...this.validationProps("password")}
        />
        <Box mb={3}>
          <Link onClick={this.send}>Resend code</Link>
        </Box>
        <Box mb={1}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={this.submit}
          >
            Submit
          </Button>
        </Box>
      </form>
    );
  }

  showComponent() {
    Analytics.pageView("/forgot-password");

    return (
      <AuthContainer>
        <MainPanel
          title="Reset your password"
          bottomText="Remembered your password?"
          action="Back to sign in"
          bottomActionHandler={() => super.changeState("signIn")}
        >
          {this.state.delivery ? this.submitView() : this.sendView()}
        </MainPanel>
      </AuthContainer>
    );
  }
}

export default CustomForgotPassword;
