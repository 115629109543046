import createBreakpoints from "@material-ui/core/styles/createBreakpoints";
import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";

const breakpoints = createBreakpoints({});

let theme = createTheme({
  palette: {
    primary: {
      main: "#515696",
      dark: "#2E3161",
      purple: "#515696",
      opacity: "rgba(46, 49, 97, .5)",
      slashscore100: "#3D3E5D",
      slashscore80: "#585A81",
      slashscore50: "#9698B0",
      slashscore20: "#D5D6DF",
    },
    background: {
      default: "#FFFFFF",
      light: "#D7DBEA",
      main: "#EAEEF9",
      opacity: "rgba(255, 255, 255, .5)",
      lightgrey: "#F8F8F8",
    },
    secondary: {
      main: "#FDB605",
      attention: "#F2545B",
      success: "#0CBABA",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 995,
      lg: 1430,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "'Oxygen', sans-serif",
    h1: {
      fontSize: "6rem",
      fontWeight: "700",
    },
    h2: {
      color: "#2E3161",
      fontWeight: "700",
      fontSize: "3.8rem",
    },
    h3: {
      color: "#2E3161",
      fontWeight: "700",
      fontSize: "2.5rem",
    },

    h4: {
      color: "#2E3161",
      fontWeight: "600",
    },

    h5: {
      color: "#2E3161",
      fontWeight: "700",
      fontSize: "1.5rem",
    },

    h6: {
      color: "#2E3161",
      fontWeight: "700",
      fontSize: "1.3rem",
    },

    subtitle1: {
      color: "#2E3161",
      fontSize: "1.2rem",
      fontWeight: "700",
    },

    subtitle2: {
      fontWeight: "600",
      textTransform: "uppercase",
      fontSize: "0.7rem",
    },

    body1: {
      fontSize: "1.2rem",
    },

    body2: {
      fontSize: "1rem",
    },

    overline: {
      color: "#333333",
      fontWeight: "bold",
      fontSize: ".9rem",
      textTransform: "capitalize",
    },
  },
  overrides: {
    Header: {
      maxHeight: 70,
    },
    Container: {
      content: {
        maxWidth: 1200,
      },
    },
    MuiLink: {
      root: {
        cursor: "pointer",
      },
    },
    MuiButton: {
      root: {
        fontWeight: "bold",
      },
      contained: {
        boxShadow: "none",
        padding: "9px 14px",
      },
      outlined: {
        borderWidth: 2,
        padding: "8px 14px",
      },
      outlinedPrimary: {
        color: "#2E3161",
        borderColor: "#2E3161",
      },
      containedPrimary: {
        backgroundColor: "#2E3161",
      },
      containedSecondary: {
        color: "#2E3161",
      },
    },
    MuiBadge: {
      dot: {
        background: "#F2545B",
        height: ".8rem",
        width: ".8rem",
        border: "2px solid #FFFFFF",
        borderRadius: "5px",
      },
    },

    MuiAccordion: {
      root: {
        boxShadow: "none",
        backgroundColor: "transparent",

        "&:before": {
          height: 0,
        },
      },
    },

    MuiAccordionDetails: {
      root: {
        padding: 0,
        backgroundColor: "#fff",
        borderRadius: "10px",
      },
    },

    MuiAccordionSummary: {
      root: {
        padding: 0,
        minHeight: 0,

        "&.Mui-expanded": {
          minHeight: 0,
        },
      },

      content: {
        margin: 0,
        alignItems: "center",

        "&.Mui-expanded": {
          margin: 0,
        },
      },

      expandIcon: {
        color: "#2E3161",
        transition: "none",
      },
    },

    MuiChip: {
      deleteIcon: {
        color: "#FFFFFF",
      },
      root: {
        backgroundColor: "#2E3161",
        color: "#FFFFFF",
        fontSize: "1rem",
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(234, 238, 249, .85)",
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
      },
      popupIndicator: {
        "& svg": {
          fontSize: "2rem",
          color: "#2E3161",
        },
      },
      listbox: {
        color: "#3D3E5D",
        fontSize: "0.875rem",
      },
    },
    MuiOutlinedInput: {
      root: {
        height: 40,
        borderRadius: 2,
      },
      adornedEnd: {
        paddingRight: "10px",
      },
      input: {
        color: "#3D3E5D",
        fontSize: "0.875rem",
        "&:-webkit-autofill": {
          "-webkit-box-shadow": "0 0 0 30px white inset",
        },
      },
    },
    MuiListItem: {
      root: {
        "&.Mui-selected": {
          backgroundColor: "#F8F8F8",

          "& .MuiListItemText-primary": {
            fontWeight: "bold",
          },
        },
      },
    },
    MuiMenuItem: {
      root: {
        minHeight: "3.5rem",
      },
    },

    MuiGrid: {
      container: {
        height: "100%",
      },
    },

    MuiListItemIcon: {
      root: {
        minWidth: 30,
      },
    },

    MuiListItemText: {
      primary: {
        color: "#3D3E5D",
      },
    },
    MuiSelect: {
      root: {
        display: "flex",
        alignItems: "center",
      },
      select: {
        "&:focus": {
          backgroundColor: "none",
        },
      },
      icon: {
        fontSize: "2rem",
        color: "#2E3161",
        top: "calc(50% - 1rem)",
      },
    },
    MuiBottomNavigationAction: {
      root: {
        minWidth: 60,
      },
    },
    MuiAlert: {
      filledInfo: {
        backgroundColor: "#515696"
      }
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          [breakpoints.down("sm")]: {
            fontSize: "75%", // 1rem = 12px
          },
          [breakpoints.down("md")]: {
            fontSize: "81.25%", // 1rem = 12px
          },
          [breakpoints.up("md")]: {
            fontSize: "75%", // 1rem = 12px
          },
          [breakpoints.up("lg")]: {
            fontSize: "81.25%", // 1rem = 13px
          },
          [breakpoints.up("xl")]: {
            fontSize: "87.5%", // 1rem = 14px
          },
        },

        body: {
          margin: "0 !important",
          backgroundColor: "#D7DBEA",
        },

        a: {
          textDecoration: "none",
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
