import React from "react";
import { Box, Button, Card, Grid, Typography } from "@material-ui/core";

const MainPanel = (props) => {
  const { title, children, bottomText, action, bottomActionHandler } = props;

  return (
    <>
      <Box mb={3}>
        <Card>
          <Box p={2}>
            <Grid container direction="column" spacing={4}>
              <Grid item>
                <Typography component="h1" variant="h5" gutterBottom>
                  {title}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>{children}</Grid>
          </Box>
        </Card>
      </Box>
      <Box pb={2} color="common.white">
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <Typography variant="caption" color="inherit">
              {bottomText}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="text"
              color="inherit"
              size="large"
              onClick={bottomActionHandler}
            >
              {action}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default MainPanel;
