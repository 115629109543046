import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  Icon,
  Fade,
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    background: "linear-gradient(172.62deg, #515695 0%, #2E3161 100%)",
    alignItems: "top",

    [theme.breakpoints.up("md")]: {
      alignItems: "center",
    },
  },

  header: {
    zIndex: 100,
  },

  container: {
    position: "relative",
    zIndex: 99,
  },
}));

const AuthContainer = (props) => {
  const classes = useStyle();
  const [imageLoaded, setImageLoaded] = useState(false);

  const pathname = useLocation().pathname;

  return (
    <Box className={classes.root}>
      <AppBar elevation={0} color="transparent" className={classes.header}>
        <Box color="white" p={2}>
          <Button
            variant="text"
            color="inherit"
            size="large"
            component="a"
            href="https://www.slashscore.ai"
            startIcon={<Icon>arrow_back</Icon>}
          >
            Back to website
          </Button>
        </Box>
      </AppBar>

      <Container maxWidth="md" className={classes.container}>
        <Box pt={8}>
          <Grid container alignItems="center" justifyContent="center">
            <Hidden smDown>
              <Grid item md={5} lg={6}>
                <Box mb={8}>
                  <Fade in={imageLoaded}>
                    <img
                      style={{ maxWidth: "100%" }}
                      onLoad={() => setImageLoaded(true)}
                      src={
                        pathname === "/enterprise"
                          ? "/images/recruiter_hero.png"
                          : "/images/developer_hero.png"
                      }
                      alt="hero"
                    />
                  </Fade>
                </Box>
              </Grid>
              <Grid item md={1}></Grid>
            </Hidden>

            <Grid item xs={12} sm={10} md={5} lg={5}>
              {props.children}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default AuthContainer;
