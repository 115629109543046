import React from "react";
import Analytics from "../../services/analytics";
import Sentry from "../../services/sentry";

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Initial
    this.module = props.module;
    this.state = { hasError: false, snackbarOpened: false };

    // Binds
    this.handleClose = this.handleClose.bind(this);
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, snackbarOpened: true };
  }

  handleClose() {
    this.setState({ snackbarOpened: false });
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, errorInfo);
    Analytics.exception(`[${this.module}] ${error}`);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Snackbar
          autoHideDuration={3000}
          open={this.state.snackbarOpened}
          onClose={this.handleClose}
        >
          <Alert onClose={this.handleClose} variant="filled" severity="error">
            Oops, something went wrong.
          </Alert>
        </Snackbar>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
