import React from "react";

export const GoogleIcon = (props) => {
  const width = props.width || "39";
  const height = props.height || "40";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 39 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.9796 20.3742C38.9796 18.7354 38.847 17.5396 38.5598 16.2994H19.8887V23.696H30.8482C30.6273 25.5342 29.4342 28.3024 26.7826 30.1625L26.7454 30.4102L32.6489 34.9938L33.0579 35.0347C36.8141 31.5578 38.9796 26.4421 38.9796 20.3742"
        fill="#4285F4"
      />
      <path
        d="M19.8884 39.8626C25.2577 39.8626 29.7653 38.0908 33.0577 35.0348L26.7824 30.1626C25.1031 31.3363 22.8492 32.1557 19.8884 32.1557C14.6296 32.1557 10.1662 28.6789 8.57509 23.8733L8.34188 23.8931L2.20332 28.6545L2.12305 28.8781C5.39325 35.389 12.1105 39.8626 19.8884 39.8626Z"
        fill="#34A853"
      />
      <path
        d="M8.57464 23.8733C8.15482 22.6331 7.91186 21.3043 7.91186 19.9313C7.91186 18.5582 8.15482 17.2295 8.55255 15.9893L8.54143 15.7252L2.32599 10.8873L2.12263 10.9843C0.774834 13.6861 0.00146484 16.7201 0.00146484 19.9313C0.00146484 23.1424 0.774834 26.1763 2.12263 28.8782L8.57464 23.8733"
        fill="#FBBC05"
      />
      <path
        d="M19.8883 7.70674C23.6225 7.70674 26.1414 9.32338 27.5777 10.6744L33.19 5.18216C29.7432 1.97102 25.2576 2.28882e-05 19.8883 2.28882e-05C12.1104 2.28882e-05 5.39323 4.47346 2.12305 10.9843L8.55297 15.9893C10.1661 11.1837 14.6295 7.70674 19.8883 7.70674"
        fill="#EB4335"
      />
    </svg>
  );
};
