import React from "react";
import { useLocation } from "react-router-dom";

import GoogleButton from "./SignInUpProviders/GoogleButton";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  absolute: {
    position: "absolute",
    left: "50%",
    bottom: "-8.5px",
    transform: "translateX(-50%)",
    backgroundColor: "white",
    padding: "0 1rem",
  },
}));

const SignInUpProviders = (props) => {
  const classes = useStyle();
  const { type } = props;

  const pathname = useLocation().pathname;

  if (pathname.includes("/enterprise")) return null;

  return (
    <Box mt={4}>
      <Box mb={1} position="relative" borderBottom="1px solid #D5D6DF">
        <Box className={classes.absolute}>
          <Typography variant="body2" component="div">
            <Box color="primary.opacity">OR</Box>
          </Typography>
        </Box>
      </Box>

      <Grid container>
        <Grid item xs={12}>
          <GoogleButton text={`${type} with google`} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignInUpProviders;
