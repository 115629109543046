import React from "react";
import { Auth } from "aws-amplify";

import { GoogleIcon } from "../CustomIcons";

import { Box, Button } from "@material-ui/core";

const GoogleButton = (props) => {
  const { text } = props;

  return (
    <Box mt={2}>
      <Button
        onClick={() => Auth.federatedSignIn({ provider: "Google" })}
        variant="outlined"
        color="primary"
        fullWidth
        startIcon={<GoogleIcon width="19" height="20" />}
      >
        {text}
      </Button>
    </Box>
  );
};

export default GoogleButton;
