import React from "react";
import { Auth } from "aws-amplify";
import { SignIn } from "aws-amplify-react";
import {
  Box,
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";

import Analytics from "../../services/analytics";

import { getError } from "../helpers/formValidation/auth";
import AuthContainer from "./common/AuthContainer";
import MainPanel from "./common/MainPanel";
import PasswordField from "./common/PasswordField";
import SignInUpProviders from "./common/SignInUpProviders";

export class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state = { loading: false, error: null };
  }

  async signIn(e) {
    e.preventDefault();
    this.setState({ loading: true });

    const { username = "", password = "" } = this.inputs;

    if (!username.replace(/\s/g, "").length)
      this.setState({ loading: false, error: "Username cannot be empty" });
    else
      try {
        const user = await Auth.signIn(username, password);
        this.checkContact(user);
        this.setState({ loading: false });
      } catch (error) {
        if (error.message === "User is not confirmed.")
          this.changeState("confirmSignUp", username);
        if (error.code === "InvalidParameterException")
          this.setState({
            loading: false,
            error: "Invalid username format",
          });
        else this.setState({ loading: false, error: error.message });
      }
  }

  handleInputChange(e) {
    this.setState({ error: null });
    super.handleInputChange(e);
  }

  validationProps(field) {
    if (!this.state.error) {
      return;
    }

    const { fields, message } = getError(this.state.error);

    return {
      error: fields.includes(field),
      helperText: fields[fields.length - 1] === field ? message : "",
    };
  }

  showComponent() {
    Analytics.pageView("/sign-in");

    return (
      <AuthContainer>
        <MainPanel
          title="Sign in"
          bottomText="You don't have an account?"
          action="Sign up"
          bottomActionHandler={() => this.changeState("signUp")}
        >
          <Box position="relative">
            <form onSubmit={this.signIn}>
              <Box mb={1}>
                <Typography
                  variant="overline"
                  component="label"
                  htmlFor="username"
                >
                  Username
                </Typography>
                <TextField
                  required
                  id="username"
                  key="username"
                  name="username"
                  type="text"
                  variant="outlined"
                  placeholder="Username"
                  autoComplete="username"
                  onChange={this.handleInputChange}
                  disabled={this.state.loading}
                  fullWidth
                  {...this.validationProps("username")}
                />
              </Box>
              <PasswordField
                required
                handleInputChange={this.handleInputChange}
                id="password"
                label="Password"
                disabled={this.state.loading}
                {...this.validationProps("password")}
              />
              <Box mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={this.state.loading}
                >
                  SIGN IN
                  {this.state.loading && (
                    <Box ml={2}>
                      <CircularProgress size="1rem" />
                    </Box>
                  )}
                </Button>
              </Box>
            </form>

            <Box mt={2} textAlign="center">
              <Link
                variant="body2"
                onClick={() => super.changeState("forgotPassword")}
              >
                Forgot password?
              </Link>
            </Box>

            <SignInUpProviders type="Sign in" />
          </Box>
        </MainPanel>
      </AuthContainer>
    );
  }
}

export default CustomSignIn;
