import * as SentryBrowser from "@sentry/browser";
import { ExtraErrorData, CaptureConsole } from "@sentry/integrations";
import { Integrations } from "@sentry/tracing";

const config = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  debug: process.env.REACT_APP_ENVIRONMENT !== "production",
  tracesSampleRate: 0.2,
  normalizeDepth: 6,
  integrations: [
    new CaptureConsole({ levels: ["error"] }),
    new ExtraErrorData({ depth: 10 }),
    new Integrations.BrowserTracing(),
  ],
};

const _noop = () => {};
const _shield = (enabled, cb) => (enabled ? cb : _noop);

// GA Analytics Service
const Sentry = () => {
  // The service is enabled if it has a DSN
  const isEnabled = !!config.dsn;

  const initialize = () => {
    SentryBrowser.init(config);
  };

  const captureException = (error, errorInfo) => {
    SentryBrowser.withScope((scope) => {
      scope.setExtras(errorInfo);
      SentryBrowser.captureException(error);
    });
  };

  return {
    initialize: _shield(isEnabled, initialize),
    captureException: _shield(isEnabled, captureException),
  };
};

// Exports
export default Sentry();
