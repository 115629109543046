const {
  NODE_ENV,
  REACT_APP_DOMAIN,
  REACT_APP_AWS_REGION,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_AWS_USERPOOL_ID,
  REACT_APP_AWS_USERPOOL_CLIENT_ID,
  REACT_APP_AWS_IDENTITYPOOL_ID,
  REACT_APP_AWS_APPSYNC_ENDPOINT,
  REACT_APP_AWS_APPSYNC_AUTH_TYPE,
  REACT_APP_WEB_ENDPOINT,
  REACT_APP_OAUTH_DOMAIN,
} = process.env;

const awsConfig = {
  aws_appsync_region: REACT_APP_AWS_REGION,
  aws_appsync_graphqlEndpoint: REACT_APP_AWS_APPSYNC_ENDPOINT,
  aws_appsync_authenticationType: REACT_APP_AWS_APPSYNC_AUTH_TYPE,
  Auth: {
    region: REACT_APP_AWS_REGION,
    userPoolId: REACT_APP_AWS_USERPOOL_ID,
    userPoolWebClientId: REACT_APP_AWS_USERPOOL_CLIENT_ID,
    identityPoolId: REACT_APP_AWS_IDENTITYPOOL_ID,
    cookieStorage: {
      domain: REACT_APP_DOMAIN,
      path: "/",
      expires: 30,
      secure: NODE_ENV !== "development",
    },
    oauth: {
      domain: REACT_APP_OAUTH_DOMAIN,
      scopes: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: REACT_APP_WEB_ENDPOINT,
      redirectSignOut: REACT_APP_WEB_ENDPOINT,
      responseType: "code",
    },
  },
  Storage: {
    AWSS3: {
      bucket: REACT_APP_STORAGE_BUCKET,
      region: REACT_APP_AWS_REGION,
    },
  },
  Analytics: {
    disabled: true,
  },
};

export const groups = {
  admin: "Admin",
  default: "Developer",
  enterprise: "Enterprise",
};

export default awsConfig;
