import React from "react";
import { Auth } from "aws-amplify";
import { ConfirmSignUp } from "aws-amplify-react";

import Analytics from "../../services/analytics";

import { getError } from "../helpers/formValidation/auth";
import AuthContainer from "./common/AuthContainer";
import MainPanel from "./common/MainPanel";

import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  CircularProgress,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";

const useStyle = (theme) => ({
  resendCode: {
    "&:hover": {
      cursor: "pointer",
      textDecoration: "underline",
    },
  },
});

export class CustomConfirmSignUp extends ConfirmSignUp {
  constructor(props) {
    super(props);
    this._validAuthStates = ["confirmSignUp"];
    this.state = { loading: false, error: null };
  }

  async confirm() {
    this.setState({ loading: true });
    const username = this.usernameFromAuthData() || this.inputs.username;
    const password = this.props.authData.password;
    const code = this.inputs.code;
    const clientMetadata = { pathname: window.location.pathname };

    try {
      await Auth.confirmSignUp(username, code, { clientMetadata });
      const authData = await Auth.signIn(username, password);

      this.setState({ loading: false });
      this.changeState("signedIn", authData);
    } catch (error) {
      this.setState({ loading: false, error: error.message });
    }
  }

  resend() {
    var username = this.usernameFromAuthData() || this.inputs.username;
    Auth.resendSignUp(username)
      .then(() => this.error("Code resent successfully!"))
      .catch((error) => this.error(error));
  }

  handleInputChange(e) {
    this.setState({ error: null });
    super.handleInputChange(e);
  }

  validationProps(field) {
    if (!this.state.error) {
      return;
    }

    const { fields, message } = getError(this.state.error);

    return {
      error: fields.includes(field),
      helperText: fields[fields.length - 1] === field ? message : "",
    };
  }

  showComponent() {
    Analytics.pageView("/signup/confirm");

    return (
      <AuthContainer>
        <MainPanel
          title="Confirm Sign Up"
          action="Back to sign in"
          bottomActionHandler={() => super.changeState("signIn")}
        >
          <form>
            <Box mb={1}>
              <Typography
                variant="overline"
                component="label"
                htmlFor="username"
              >
                Username
              </Typography>

              <TextField
                disabled
                id="username"
                name="username"
                key="username"
                type="text"
                variant="outlined"
                fullWidth
                value={this.usernameFromAuthData()}
              />
            </Box>

            <Box mb={1}>
              <Typography variant="overline" component="label" htmlFor="email">
                Email
              </Typography>

              <TextField
                disabled
                id="email"
                name="email"
                key="email"
                type="text"
                variant="outlined"
                fullWidth
                value={this.props.authData.attributes?.email}
              />
            </Box>

            <Box mb={1}>
              <Typography variant="overline" component="label" htmlFor="code">
                Confirmation code
              </Typography>

              <TextField
                required
                id="code"
                name="code"
                key="code"
                type="number"
                variant="outlined"
                fullWidth
                disabled={this.state.loading}
                onChange={this.handleInputChange}
                {...this.validationProps("code")}
              />
            </Box>
            <Box mb={2}>
              <Typography variant="body2">
                We have sent an email with a confirmation code to your email
                address. Lost your code?{" "}
                <Link onClick={this.resend}>Resend it</Link>
              </Typography>
            </Box>
            <Box mb={1}>
              <Button
                onClick={this.confirm}
                disabled={this.state.loading}
                variant="contained"
                color="primary"
                fullWidth
              >
                Confirm
                {this.state.loading && (
                  <Box ml={2}>
                    <CircularProgress size="1rem" />
                  </Box>
                )}
              </Button>
            </Box>
          </form>
        </MainPanel>
      </AuthContainer>
    );
  }
}

export default withStyles(useStyle)(CustomConfirmSignUp);
